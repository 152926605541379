.leagueText {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 420px;
    text-align: center;

    h1, h3 {
        font-weight: 600;
        color: white;
    }

    h1 {
        font-size: 20px;
    }

    h3 {
        font-size: 13px;
        line-height: 21.47px;
    }

    @media screen and (max-height: 680px) {
        margin-top: 5px;
    }

    @media screen and (max-height: 620px) {
        gap: 10px;
    }
}

.leagueListContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.leagueBar {
    padding: 0 20px;
    width: 100%;
    display: flex;
    justify-content: start;
    flex-direction: column;

    .energy-counter {
        display: flex;
    justify-content: center;
    align-items: end;
    }
}

.trophy {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;

    img {
        height: 200px;
    }

    @media screen and (max-height: 750px) {
        margin-top: 5px;
    }

    @media screen and (max-height: 610px) {
        img {
            height: 150px;
            margin-top: 20px;
        }
    }

    @media screen and (max-height: 575px) {
        img {
            margin-top: 0;
        }
    }
}

.league-navigation {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    top: 20%;

    button {
        background: none;
        border: none;
        outline: none;

        img {
            height: 30px;
        }

        &:last-child {
            img {
                transform: rotate(180deg);
            }
        }
    }
}
.leaguePlayer{
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid;
}