
$border-width: 3px;
$top-margin:30vh;
$top_margin_observe:5vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);
.outer-container_settings{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top_margin_observe);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow:
    3px 3px 20px rgba(251, 79, 255, 0.3), 
    -3px -3px 20px rgba(37, 177, 255, 0.3), 
    3px -3px 20px rgba(251, 79, 255, 0.3), 
    -3px 3px 20px rgba(37, 177, 255, 0.3); 
    margin-top: $top_margin_observe;

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: 0;
    right: 0;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_settings {
    width: 100%;
    height: calc(100% - 6px);
    padding: 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #111111;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
    margin-bottom: -6px;
}
.settings_text {
    text-align: center;
    font-weight: 600;
    font-size: 28px;
    height: 100%;
    padding-top: 10px;
}

.settings-container {
    background-color: #121212;
    padding: 20px;
    border-radius: 10px;
    color: #fff;
    width: 100%;
}

.settings-block {
    display: flex;
    flex-direction: column;
}

.settings-item {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #2a2a2a;
    border-radius: 20px;
    margin-bottom: 10px;
    justify-content: space-between;
}

.settings-icon {
    height: 30px;
}

.settings-text {
    display: flex;
    flex-direction: column;
}

.settings-title {
    font-size: 18px;
    font-weight: bold;
}

.settings-subtitle {
    font-size: 14px;
    color: #bbb;
}

.settings-item:last-child {
    margin-bottom: 0;
}
.lenguague_con {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #2a2a2a;
    border-radius: 20px;
    margin-bottom: 10px;
    justify-content: space-between;
    color: white;
}
.language-switcher{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.selected_lenguague{
    color: #7d7d7d;
    font-size: 12px;
}