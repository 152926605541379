$border-width: 3px;
$top-margin:30px;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

.outer-container {
    margin-top: $top-margin;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top-margin);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow:
    3px 3px 20px rgba(251, 79, 255, 0.3), 
    -3px -3px 20px rgba(37, 177, 255, 0.3), 
    3px -3px 20px rgba(251, 79, 255, 0.3), 
    -3px 3px 20px rgba(37, 177, 255, 0.3); 

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: $border-width;
    right: $border-width;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}
.gradient {
  display: inline-block;
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background: linear-gradient(45deg, #FA00FF, #06DBFB);
    z-index: -1;
  }
}

.wrapper {
  border-radius: $border-radius-input;
  padding: $border-width;
  box-shadow: 1px 2px 7px 2px rgba(#333, 0.75);
}

.input {
  border-radius: $border-radius-input - $border-width;
  border: 1px solid #222;
  background: #37323c;
  color: #eee;
  box-shadow: 0 0 8px rgba(#111, 0.7) inset;
  padding: 0.85em 1em;
  font-size: 1em;
  width: 80vw;
  text-align: center;
  
  &:focus {
    outline: none;
  }
  &::placeholder {
    opacity: 1;
    color: #eee;
  }
}



body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.3;
 
}
.info {
  max-width: 40vw;
  margin: 0.5em 1em;
}
.pet_img_naming {
  max-width: 65%;
  margin: 12vh 0;
}
.container_p40 {
  width: calc(100% - 2*$border-width); 
  height: calc(100% - 2*$border-width);
  padding: 5vw;
  margin: $border-width;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #000; 
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  position: relative;
  z-index: 1; 
  
}
.under_text {
  font-size: 1.2rem;
  font-weight: 100;
  opacity: 0.6;
  margin-top: 5px;
  margin-bottom: 50px;
}
.btn_naming_submit{
  background: #FF9533;
  text-align: center;
  font-size: 1.4rem;
  width: 100%;
  border-radius: 15px;
  padding: 15px;
  color: white;
  border: none;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pet_container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.pet-glow {
  position: fixed;
  width: 100%;
  height: 480px;
  background: radial-gradient(#90408D, rgb(255 255 255 / 0%) 68%);
  transform: translate(0%, 0%);
  z-index: -1;
}