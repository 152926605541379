$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(90deg, #FB4FFF, #25B1FF);

.container_glow_shop {
  padding: 0 3vw 0 3vw;
  margin: 3px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #0b0c11;
  position: relative;
  z-index: 1;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.shop_grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  width: 100%;
  margin-bottom: 140px;

  &.first {
    margin-bottom: 0;
  }
}

.modal-container-tasks {
  width: 102%;
  border-radius: 30px 30px 0 0;
  position: absolute;
  bottom: 0;
  left: -5px;
  background: linear-gradient(to bottom, #202835, #0b0c11);
  border-radius: 30px 30px 0 0;
  border-top: 3px solid rgb(102, 252, 241);
  box-shadow: 0px -10px 50px 0px rgb(70, 162, 159);
}

.modal-content_tasks {
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  padding: 25px 22px 25px;
}

.modal_image_tasks {
  width: 120px;
  height: 120px;
  text-align: center;
  font-size: 4rem;
  margin-bottom: 20px;
}

.coin-icon {
  width: 25px;
  height: 25px;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
}

.tab-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    font-size: 20px;
    font-weight: 500;
  }

  img{
    width: 50vw;
    margin-top:5vh
  }

   
  
}

.tab_img_tasks {
  width: 396px;
  z-index: -1;
}

.tab-title {
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 8px;
}

.tasks_con {
  display: flex;
  align-items: center;
  background: #202835;
  border-radius: 20px;
  padding: 15px;
  color: white;
  justify-content: space-between;

  .additionalWrap {
    display: flex;
    align-items: center;
  }
}

.name {
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  margin-bottom: 5px;
}

.img_friends {
  width: 45px;
  margin-right: 10px;
  border-radius: 10px;
}

.coins_reward {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  font-size: 18px;
  font-weight: 700;
  justify-content: start;

  img {
    width: 24px;
    height: 24px;
    margin-right: 1px;
  }
}

.coin_icon_task {
  width: 25px;
  height: 25px;
}
.daily_text{
  width: 100%;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 20px;
}

.space {
  margin-bottom: 30px;
}

.button_tasks {
  background: #1F87FF;
  color: white;
  padding: 20px;
  border-radius: 15px;
  font-size: 24px;
  margin: 0 10px;
  border: none;
  font-weight: 700;
  width: 100%;
}

.done_tasks{
  color: #17D08E;
  pointer-events: none;
}
.coin_icon_modal_tasks {
  width: 30px;
}
.coins_container_tasks {
  display: flex;
  font-weight: 700;
  align-items: center;
  font-size: 24px;
  justify-content: center;
  margin: 25px 0;
}

.iconWrapper {
  display: flex;
  align-items: center;
}

.task_status {
  display: flex;
  align-items: center;
}

.button_tasks_join {
  color: #FFF;
  font-size: 16px;
  font-weight: 700;
  padding: 12px 38px;
  background-color: #1F87FF;
  width: fit-content;
  margin-top: 25px;
  border-radius: 15px;
  border: none;
}

.img_open {
  height: 36px;
}