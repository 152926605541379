$border-width: 3px;
$top-margin:3vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

.speed-up {
  animation: play 0.2s steps(10) infinite;
}

@keyframes play {
  100% {
      background-position: -1000px;
  }
}

.outer-container_home {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 90vh;
  box-sizing: border-box;
  position: relative;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
  margin-bottom: -20px;
  flex-direction: column;
}



.container_p40 {
  width: calc(100% - 2*$border-width); 
  height: calc(100% - 2*$border-width);
  padding: 5vw;
  margin: $border-width;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #111111; 
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  position: relative;
  z-index: 1; 
  
}

.header_pet {
  margin: 5vw;
  background: linear-gradient(90deg, rgba(0, 194, 255, 0.67) 0%, rgba(255, 0, 230, 0.38) 100%);
  border-radius: 10px;
  padding: 12px;
  text-decoration: none;
  color: white;
  min-height: 64px;
}

.balance_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  h2 {
    font-size: 20px;
    font-size: 400;
    text-align: center;
  }
}

.row_home {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
}
.tg_btn{
    background: #282B30;
    padding: 5px;
    border-radius: 15px;
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}

.glow_effect_container {
    width: 100vw;
    height: 90%;
    display: flex;
    flex-direction: column;
    background: #111111;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
}

.stats {
  margin-top: 18px;
  display: flex;
  gap: 15px;
}

.stats-coin {
    width: 65px;
    height: auto;
}
.coin-count {
    font-size: 48px;
    font-weight: 700;
    margin-left: -7px;
}
.energy {
  height: 100%;
  background: linear-gradient(90deg, #FB4FFF 25%, #25B1FF 61%);
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: width 0.1s ease;
}
.energy-wrapper {
  width: 90%;
  display: flex;
  margin-bottom: 4vh;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
}

.energy-icon {
  width: 36px;
  height: 46px;
}

.energy-counter {
  margin-right: 4px;
  font-weight: 700;
  font-size: 16px;

  span {
    font-size: 24px;
  }
}

.energy-bar {
  width: 100%;
  height: 18px;
  box-shadow: -25.54px 13.01px 57.33px 0px #3324083F,
              8.06px -4.11px 50.05px 0px #2cd6cd  inset,
              -8.06px 4.11px 9.05px 0px #2cd6cd  inset;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

@media screen and (max-height: 622px) {
  .energy-counter {
    font-size: 14px;
    span {
      font-size: 20px;
    }
  }

  .energy-wrapper {
    gap: 3px;
  }

  .energy-bar {
    height: 12px;
  }
}

.boost_btn_img {
  width: 25px;
  margin-bottom: 5px;
}
.boost_btn {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 5px;
  border-radius: 10px;
  color: white;
  text-decoration: none;
  font-size: 20px;
  gap: 10px;
}
.league_text {
  font-size: 1rem;
  color: white;
  text-decoration: none;
  font-weight: 300;
}
.league_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  z-index: 1000;
}
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.gameplay-coin img {
  width: 65vw;
  max-width: 450px;
  height: auto;
  transition: transform 0.1s ease-out;
  perspective: 1000px;
  z-index: 1;
  position: relative;
}
.header{
  flex-direction: row;
}
.settings {
  width: 26%;
  height: 100%;
  background: linear-gradient(270deg, rgb(96 118 223), rgb(157 141 234));
  border-radius: 10px;
  padding: 6px;
  text-decoration: none;
  color: white;
  min-height: 64px;
}
.setting_logo{
  border-radius: 10px;
  height: 40px;
  object-fit: cover;
  width: 40px;
}
.setting_name{
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}
.img_clicker {
  transition: transform 100ms ease-in-out;
}

.img_clicker.scale {
  transform: scale(0.99);
}
.row_home {
  display: flex;
  flex-direction: row !important;
}
@media screen and (max-height: 833px) {
  .gameplay-coin img {
    width: 40vw;
  }
}
@media screen and (max-height: 714px) {
  .gameplay-coin img {
    width: 47vw;
  }
}
.level-indicator {
  color: #fff;
  padding: 0 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}

.level-info {
  display: flex;
  align-items: center;
}
.level_container{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.progress_bar_home {
  width: 100%;
  height: 10px;
  background: rgba(213, 213, 213, 0.4117647059);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-top: 10px;
}
.progress_home {
  height: 100%;
  background: linear-gradient(90deg, #A4E1A8 33%, #C88EF2 66%, #7068F6 100%);
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: width 0.1s ease;
}

.trophy-icon {
  width: 17px;
  height: 17px;
  margin-right: 5px;
}

.level-name {
  font-weight: bold;
}

.level-arrow {
  margin-left: 5px;
}

.progress-bar {
  background-color: #404954;
  flex-grow: 1;
  height: 10px;
  border-radius: 5px;
  margin: 0 20px;
  position: relative;
}

.progress-filled {
  background: linear-gradient(to right, #6e7ff3, #c158dc);
  height: 100%;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
}

.level-text {
  min-width: 60px;
  text-align: center;
}

.levels {
  font-size: 15px;
  font-weight: 700;
  position: absolute;
  right: 20px;
}

.leagueImg {
  width: 30px;
  height: 30px;
}

.leagueContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .tab-button-arrow {
    margin-left: 0;
    justify-content: start;
    align-items: center;
    display: flex;
    color: white;
  }
}

.leagueName {
  font-weight: 700;
  font-size: 20px;
  color: #CACACA;
}
@keyframes playZoneAnimation {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(0.97, 0.97, 0.97) ;
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.play-zone {
  position: relative;
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  width: 85%;
  &.animate {
    animation: playZoneAnimation 0.3s ease-in-out;
  }
  img{
    width: 90%;
  }
}