.pointer {
    position: absolute;
    height: 70px;
    max-width: 30px;
    top: calc(50% - 70px);
    z-index: 9 ;
}

.right{
    right: 5px;
}
.left{
    left: 5px;
    transform: rotate(180deg);

}