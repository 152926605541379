$border-width: 3px;
$top-margin:30vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

.outer_container_play {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
    height: 85vh;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: -5px 0px 20px 0px rgb(249 0 255 / 16%), 5px -2px 20px 0px #2fb5ff4d;
    position: absolute;
    z-index: 1000;
    border-radius: 30px;


  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: 0;
    right: 0;
    bottom: -$border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_play {
    width: 100%;
    height: calc(100% - 7px);
    padding: 3vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #111111;
    position: relative;
    z-index: 1;
    justify-content: space-around;
    border-radius: 30px;
}


.mobile-prompt-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('./back.png'); 
    background-size: cover;
    background-color: black;
  }
  
  .mobile-prompt {
    background: rgba(0, 0, 0, 0.5); 
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    color: white;
  }
  
  .mobile_prompt_text {
    font-size: 1.5rem;
    padding: 3vw;
    text-align: center;
}
  
.btn_container_play {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 10px; 
    align-items: center;
    justify-items: center;
    margin-bottom: 20px;
    width: 100%;
  }
  .btn_play{
    background: #FF9533;
    border: none;
    width: 100%;
    padding: 10px 5px;
    border-radius: 10px;
    color: white;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
    align-items: center;
    img{
        width: 20px;
    }
}
  
 
  