.upgrade1 {
    width: 100%;
    border-radius: 20px;
    background: #202835;

    .profit1 {
        display: flex;
        align-items: center;
        border-bottom: 2px #605860 solid;
        padding: 5px 15px;
        gap: 27px;

        .icon {
            width: 48px;
            height: 48px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .info2 {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .title {
                color: #FFF;
                font-size: 14px;
                font-weight: 500; 
            }

            .profit_info {
                .text {
                    color: #FFF;
                    font-size: 12px;
                    font-weight: 500;
                    opacity: 0.5;
                    margin-bottom: 2px;
                }
                .profit2 {
                    display: flex;
                    align-items: center;
                    color: #FFF;
                    font-size: 14px;
                    font-weight: 700; 

                    img {
                        width: 19px;
                        height: 19px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .info1 {
        display: flex;
        align-items: center;
        padding: 8px 15px;
        gap: 10px;

        .separattor {
            background-color: #605860;
            width: 2px;
            height: 30px;
        }

        .lvl {
            color: #FFF;
            font-size: 15px;
            font-weight: 700;
            margin-right: 10px;
        }

        .upgrade_cost {
            display: flex;
            align-items: center;
            color: #FFF;
            font-size: 15px;
            font-weight: 700; 

            img {
                width: 26px;
                height: 26px;
                margin-right: 5px;
            }
        }
    }
}