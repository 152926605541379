$border-width: 3px;
$top-margin:10vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

.outer_container_loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top-margin);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow:
    3px 3px 20px rgba(251, 79, 255, 0.3), 
    -3px -3px 20px rgba(37, 177, 255, 0.3), 
    3px -3px 20px rgba(251, 79, 255, 0.3), 
    -3px 3px 20px rgba(37, 177, 255, 0.3); 
    margin-top: $top-margin;

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: $border-width;
    right: $border-width;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_loading {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    padding: 3vw;
    margin: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #111111;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
}

.gradient-text {
    font-size: 2rem; 
    font-weight: bold; 
    background-image: linear-gradient(90deg, #FA00FF, #00FFE0); 
    -webkit-background-clip: text; 
    background-clip: text; 
    color: transparent; 
  }

.emoji {
    max-width: 60px;
    max-height: 60px;
    margin-right: 15px;
}
.text{
    font-size: 1.7rem;
    font-weight: 800;
}
.subtext{
    color: #CACACA;

}
.loading_container{
    width: 100%;
    padding: 1rem;
    display: grid;
}
.loading_block {
    display: flex;
    flex-direction: row !important;
    width: 100%;
    margin-bottom: 25px;
}
.talesTelegramia {
    font-family: "Kaushan Script";
    -webkit-text-stroke: 0.5px white;
    font-size: 12vw;
    color: #0088CC;
    margin-top: 35px;
}
.derishabl{
    position: absolute;
    top: 0;
    width: 60vw;
    z-index: 10;
}
.derishabl_container{
    width: 100%;
    display: flex;
    justify-content: center;
}
.glow {
    position: absolute;
    width: 420px;
    height: 420px;
    background: radial-gradient(circle at center, #2D7DDC, rgba(118, 58, 215, 0) 66%);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 0;
    overflow: visible;
    z-index: -1;
}


.loading-bar-container {
    width: 100%; 
    background-image: linear-gradient(90deg, #FFFFFF, #25B1FF); 
    border-radius: 20px; 
  }
  
  .loading-bar {
    height: 15px; 
    background-image: linear-gradient(90deg, #FB4FFF, #25B1FF); 
    border-radius: 20px; 
    width: 0%; 
    transition: width 0.01s ease-in-out; 
  }
  


@media screen and (max-width:400px) 
{
    .text {
        font-size: 1.4rem;
    }
    .emoji {
        max-width: 50px;
        max-height: 50px;
    }
    .gradient-text {
        font-size: 1.5rem;
    }
    .derishabl {
        width: 50vw;
    }
    .subtext {
        font-size: 14px;
    }
}

.loading {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background: white;
}