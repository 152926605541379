.pet {
    margin: 10px; 
    width: calc((100vw - 100px) / 3); 
  }
  
.unavaliable{
    pointer-events: none;
}

 
  
  .pets-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-items: center;
    padding: 0 10px; 
  }
  
  .pets {
    padding: 40px;
    background-image: url('./background.png');
    height: 100vh;
    width: 100vw;
    border-radius: 10px;


    .selected{
      box-shadow: 5px -5px 20px 0px rgba(251, 79, 255, 0.3),
                  -5px 5px 20px 5px rgba(37, 177, 255, 0.3), 
                  5px 5px 20px 5px rgba(251, 79, 255, 0.3), 
                  0px -5px 20px 5px rgba(37, 177, 255, 0.3);
      border-radius: 20px;
    }
    .pet_choosing_img {
      width: 100%; 
      height: auto; 
      display: block; 
      border-radius: 10px;

    }
  }
  

  .buy-pet-button {
    margin-top: 20px;
    border: none;
    background-color: #FF9533;
    color: white;
    border-radius: 25px;
    font-size: 1.3rem;
    width: 100%;
    padding: 20px;
}
  .choose_header {
    width: 100%;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 20px;
}
.noavalible_container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 600;
}
.rabbit {
    position: absolute;
    height: 85%;
}
