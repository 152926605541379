$border-width: 3px;
$top-margin:30vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

.outer_container_map {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top-margin);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow:
    3px 3px 20px rgba(251, 79, 255, 0.3), 
    -3px -3px 20px rgba(37, 177, 255, 0.3), 
    3px -3px 20px rgba(251, 79, 255, 0.3), 
    -3px 3px 20px rgba(37, 177, 255, 0.3); 
    position: absolute;
    bottom: 0;
    z-index: 1000;
  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: 0;
    right: 0;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_map{
    width: 100%;
    height: calc(100% - 6px);
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, #1F1F1F, #343434);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
}
.map{
    height: 100%;
    width: 100%;
    position: relative;
}
.island1{
    width: 30%;
    height: 35%;
    position: absolute;
    left: 0;
    top: 20%;
    z-index: 1;
}
.island2{
    position: absolute;
    width: 40%;
    height: 30%;
    bottom: 0;
    z-index: 1;

}
.talesTelegramia_map {
    font-family: "Kaushan Script";
    -webkit-text-stroke: 0.5px white;
    font-size: 10vw;
    color: #0088CC;
    margin-top: 35px;
    position: absolute;
    z-index: 10;
    top: 0;
}