$border-width: 3px;
$top-margin:30vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

// .outer_container_coming {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 100vw;
//     height: 70vh;
//     box-sizing: border-box;
//     position: relative;
//     border-top-left-radius: 30px;
//     border-top-right-radius: 30px;
//     overflow: hidden;
//     box-shadow: 3px 3px 20px rgba(251, 79, 255, 0.3), -3px -3px 20px rgba(37, 177, 255, 0.3), 3px -3px 20px rgba(251, 79, 255, 0.3), -3px 3px 20px rgba(37, 177, 255, 0.3);
//     position: absolute;
//     bottom: 0;
//     z-index: 1000;
//     margin-bottom: -3px;

  
//   &::before {
//     content: '';
//     position: absolute;
//     top: -$border-width;
//     left: 0;
//     right: 0;
//     bottom: $border-width;
//     background: $gradient;
//     z-index: -1;
//     border-radius: inherit; 
//   }
// }

// .container_glow_coming {
//     width: 100%;
//     height: calc(100% - 6px);
//     padding: 3vw;
//     margin: 0px;
//     display: flex;
//     flex-direction: column;
//     background: linear-gradient(180deg, #1F1F1F, #343434);
//     border-top-left-radius: 30px;
//     border-top-right-radius: 30px;
//     position: relative;
//     z-index: 1;
//     justify-content: center;
//     align-items: center;
// }

   
.shop-button {
    font-size: 1.1rem;
    width: 90%;
    min-height: 48px;
    padding: 12px 8px;
    margin-top: 60px;
    background-color: #FF9533;
    color: white;
    font-weight: normal;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.img_modals{
    width: 40vw;
}
.text_modal{
    font-size: 1.3rem;
    text-align: center;
    margin: 4vh 0;
}